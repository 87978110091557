html,
body,
#root {
  height: 100%;
}

/* Hacks to make Toast component work */
.toast[aria-hidden="true"] {
  right: -40em !important;
}
.toast {
  z-index: 1;
}

.choice {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 550px;
}

.choice__item {
  width: 220px;
  height: 88px;
  margin-top: 16px;
}

.choice-item__title {
  font-weight: 100 !important;
  padding-left: 0 !important;
  max-width: 100% !important;
}

.tabs__tab {
  margin-bottom: 25px !important;
}

.tabs__list::-webkit-scrollbar {
  height: 4px;
  background-color: #dfdfdf;
  cursor: pointer;
}

.tabs__list::-webkit-scrollbar-thumb {
  background-color: #000000;
}

.btn {
  width: 345px !important;
}

.btn__inner--primary {
  background: #0058a3 !important;
  color: #fff !important;
}

.btn:disabled > .btn__inner {
  background: #fff !important;
  color: #dfdfdf !important;
  border: 1px solid #dfdfdf !important;
}

li:first-child:nth-last-child(n + 1) {
  margin-right: 16px;
}

.unsubscribe-bottomText {
  margin-top: 3rem;
  margin-bottom: 0 !important;
}

.css-8087k2 {
  align-items: center;
  margin-top: 3rem;
}

.css-1axtj0a {
  justify-content: center;
}

.main-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  max-height: 100%;
  font-family: "Noto IKEA", "Noto Sans", Roboto, "Open Sans", -apple-system,
    sans-serif;
  min-height: calc(100vh - 170px);
}

.unsubscribe-bg {
  background-image: url("/images/bg_img.0a0a13de.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.sub-container {
  max-width: 46rem;
  background-color: #fff;
  padding: 30px;
  border: 32px solid #f5f5f5;
}

.css-1axtj0a {
  min-height: calc(100vh - 170px) !important;
}

.clickHereLink {
  text-decoration: underline;
}
